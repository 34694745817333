import { getCookie } from './cookies.js';

export var globalParams = {};
export var queryParams = {};
export var siteParams ={};

var initGlobalParams = function() {
    /**
     * Returns any params that are used globally
     */
    globalParams['ga_user_id'] = getCookie('_ga');
    globalParams['micro_user_id'] = getCookie('mixp_id');
    globalParams['micro_session_id'] = getCookie('mixp_session_id');
    globalParams['js_enabled'] = 1;
    globalParams['ip_address'] = '';

    // List of accepted query params
    var acceptedVals = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_term",
        "lead_attribution"
    ];

    // Extract additional parameters from URL
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        // Only track accepted query params
        if (acceptedVals.indexOf(key) > -1) {
            globalParams[key] = value;
        }
    });
}

var initQueryParams = function() {
    // List of accepted query params
    var acceptedVals = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_term",
        "mkwid",
        "lead_attribution",
        "matchtype",
        "campaign_id",
        "adgroup_id",
        "creative_id",
        "keyword_id",
        "extension_id",
        "matchtype",
        "ad_network",
        "ad_position",
        "ad_placement",
        "ad_device",
        "ad_devicemodel",
        "ad_physical_location",
        "is_paid",
        "is_spanish",
        "form_id",
        "click_id",
        "audience",
        "aff_id",
        "aff_track1",
        "aff_track2",
        "aff_track3",
        "aff_track4",
        "aff_track5",
        "utm_s",
        "utm_c",
        "utm_m",
        "utm_co",
        "utm_t",
        "ad_c",
        "ca_id",
        "ag_id",
        "kw_id",
        "cr_id",
        "ex_id",
        "ad_m",
        "ad_n",
        "ad_d",
        "ad_dm",
        "ad_pos",
        "ad_pl",
        "aud",
        "l_attr",
        "is_sp",
        "adwords_campaignid",
        "adwords_adgroup",
        "adwords_creative",
        "adwords_network",
        "adwords_matchtype",
        "adwords_feeditemid",
        "adwords_adposition",
        "adwords_placement",
        "adwords_device",
        "adwords_devicemodel",
        "adwords_location",
        "campaignid",
        "adgroup",
        "creative",
        "network",
        "adposition",
        "device",
        "devicemodel",
        "gclid",
        "msclkid",
        "fbclid",
        "assist_type",
        "campaign_type"
    ];

    // Escape URL
    var unescapedURL = window.location.href.replace(/&amp;/g, "&");

    // Extract additional parameters from URL
    unescapedURL.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        // Only track accepted query params
        if (acceptedVals.indexOf(key) > -1) {
            queryParams[key] = value;
        }
    });
}

initGlobalParams();
initQueryParams();