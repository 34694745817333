import { getCookie, setCookie } from './utils/cookies.js';
import { queryParams } from './utils/params.js';
import { _isDefined } from './utils/polyfills.js';

const MIXPANEL_CUSTOM_LIB_URL = "https://auth-srv.launchthatapps.com/lib.min.js";

(function(c,a){if(!a.__SV){var b=window;try{var d,m,j,k=b.location,f=k.hash;d=function(a,b){return(m=a.match(RegExp(b+"=([^&]*)")))?m[1]:null};f&&d(f,"state")&&(j=JSON.parse(decodeURIComponent(d(f,"state"))),"mpeditor"===j.action&&(b.sessionStorage.setItem("_mpcehash",f),history.replaceState(j.desiredHash||"",c.title,k.pathname+k.search)))}catch(n){}var l,h;window.mixpanel=a;a._i=[];a.init=function(b,d,g){function c(b,i){var a=i.split(".");2==a.length&&(b=b[a[0]],i=a[1]);b[i]=function(){b.push([i].concat(Array.prototype.slice.call(arguments,0)))}}var e=a;"undefined"!==typeof g?e=a[g]=[]:g="mixpanel";e.people=e.people||[];e.toString=function(b){var a="mixpanel";"mixpanel"!==g&&(a+="."+g);b||(a+=" (stub)");return a};e.people.toString=function(){return e.toString(1)+".people (stub)"};l="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
    for(h=0;h<l.length;h++)c(e,l[h]);var f="set set_once union unset remove delete".split(" ");e.get_group=function(){function a(c){b[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));e.push([d,call2])}}for(var b={},d=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<f.length;c++)a(f[c]);return b};a._i.push([b,d,g])};a.__SV=1.2;b=c.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
    MIXPANEL_CUSTOM_LIB_URL:"file:"===c.location.protocol&&"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js";d=c.getElementsByTagName("script")[0];d.parentNode.insertBefore(b,d)}})(document,window.mixpanel||[]);

var THIRTY_MINUTES_MILLISECONDS = 1800000;
var mp_session_config = {
    timeout: THIRTY_MINUTES_MILLISECONDS,

    get_Session_id: function() {
        //safe client-side function for generating session_id
        //from: https://stackoverflow.com/a/105074
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4()+s4()+'-'+s4()+'-'+s4()+'-'+s4()+'-'+s4()+s4()+s4();
    },
    set_Session_id: function() {
        var session_id = mp_session_config.get_Session_id();

        mixpanel.register({
            "session ID": session_id
        });
        setCookie('mixp_session_id', session_id, 365, '/');
    },
    check_Session_id: function() {
        if (!mixpanel.get_property("last event time") || !mixpanel.get_property("session ID")) {
            mp_session_config.set_Session_id();
        }
        if (Date.now() - mixpanel.get_property("last event time") > mp_session_config.timeout) {
            mp_session_config.set_Session_id();
        }
    }
}

_isDefined('mixpanel') && mixpanel.init("ef57e8a6b4d38b67d855c0b9704e64d8", {
    "cross_site_cookie": false,
    "secure_cookie": true,
    "batch_requests": false,
    api_host: 'https://auth-srv.launchthatapps.com',
    loaded: function(mixpanel) {

        if (navigator.userAgent === 'ghostbot') { mixpanel.register({"$ignore": true}); }

        //check for a session_id ... if any of the checks fail set a new session id
        mp_session_config.check_Session_id();

        //monkey patch to make sure that mixpanel.track() always checks for a session id
        var originalTrack = mixpanel.track;
        mixpanel.track = function() {
            // console.log('session checked');
            mp_session_config.check_Session_id();
            mixpanel.register({"last event time": Date.now()});
            originalTrack.apply(mixpanel, arguments);
        }

        if(!getCookie('mixp_id')) {
            var distinct_id = mixpanel.get_distinct_id();
            mixpanel.identify(distinct_id);
            mixpanel.people.set({ "USER_ID": distinct_id });
            setCookie('mixp_id', distinct_id, 365, '/');
        } else {
            //needed to enable mixpanel.people.set & set_once
            mixpanel.identify();
        }
        var params = {...queryParams};

        mixpanel.track('Page View', params);
    }
});

function getDomainFromURL(url) {
    var l = document.createElement("a");
    l.href = url;
    return l.hostname;
}

function isWistia(referrer, utmSource) {
    return referrer === '' && utmSource === 'wistia'
}

function isNewSession(referrer, utmSource) {
    return getDomainFromURL(referrer) !== getDomainFromURL(window.location) || (referrer === '' && !isWistia(referrer, utmSource))
}

if(isNewSession(document.referrer, queryParams['utm_source'])) {
    function getQueryParam(url, param) {
        // Expects a raw URL
        param = param.replace(/[[]/, "\[").replace(/[]]/, "\]");
        var regexS = "[\?&]" + param + "=([^&#]*)",
            regex = new RegExp( regexS ),
            results = regex.exec(url);
        if (results === null || (results && typeof(results[1]) !== 'string' && results[1].length)) {
            return '';
        } else {
            return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
        }
    };
    function getCampaignParams(label, url, referrer) {
        var campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term lead_attribution'.split(' ')
            , kw = ''
            , params = {};
        var index;
        for (index = 0; index < campaign_keywords.length; ++index) {
            kw = getQueryParam(url, campaign_keywords[index]);
            if (kw.length) {
                params[campaign_keywords[index] + ' [' + label + ' touch]'] = kw;
            }
        }
        params['referrer [' + label + ' touch]'] = referrer;

        return params;
    }
    var lastParams = getCampaignParams('last', document.URL, document.referrer);

    mixpanel.track('Session Start');
    mixpanel.people.set(lastParams);
    mixpanel.people.set_once(getCampaignParams('first', document.URL, document.referrer));
    mixpanel.register(lastParams);
}